import Loader from "./Loader";


const PageLoader = () => {
  return (
    <div style={{ width: "100%", height: "calc( 100vh - 75px)", position: "relative" }}>
      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <Loader />
      </div>
    </div>
  );
}

export default PageLoader;