import { useDispatch, useSelector } from "react-redux"
import Loader from "../Utils/Loader";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useEffect } from "react";
import { resumeListStart } from "../../store/actions/userAction";
import { useNavigate } from "react-router-dom";


export default () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails, resumeList } = useSelector(state => state.user);

  useEffect(() => {
    if (!userDetails.loading && Object.keys(userDetails.data).length > 0) {
      dispatch(resumeListStart());
    }
  }, [userDetails]);

  const handleClick = id => navigate(`/preview?id=${id}`);

  return (
    userDetails.loading ?
      <></>
      : Object.keys(userDetails.data).length > 0 ?
        resumeList.loading ?
          "Loading.."
          : Object.keys(resumeList.data).length > 0 ?
            <Container className="mb-5">
              <div className="row my-4 my-md-5">
                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                  <h2 className="font-35 font-weight-bold fables-second-text-color mb-4">My Resumes</h2>
                  <p className="fables-forth-text-color">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos reiciendis cum aliquid quam, consequatur.quisquam consectetur culpa commodi maxime in harum sunt nam.
                  </p>
                </div>
              </div>
              <Row className="d-flex justifuy-content-center">
                {resumeList.data.resumes.length > 0 ?
                  resumeList.data.resumes.slice(0, 4).map((template, i) =>
                    <Col lg={3} xl={3} key={template.id} >
                      <Card
                        // border={selected == template.id ? "primary" : ""}
                        className="cursor-pointer border-3"
                        onClick={e => handleClick(template.id)}
                      >
                        <Card.Img variant="top" src={template.resume_pic || "/images/empty.jpg"} />
                        <h3 className="text-center">{template.resume_name || "Untitled"}</h3>
                      </Card>
                    </Col>
                  )
                  : <div>No Resumes Found</div>
                }
              </Row >
            </Container>
            : null
        : null
  );

}