import React, { Fragment } from 'react';
import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  heading: {
    display: "flex",
    marginTop: 20,
    width: "100%",
    fontWeight: "bold",
    fontSize: 18,
  },
  divider: {
    display: "flex",
    height: 1,
    justifyContent: "center",
  },
  line: {
    width: "calc( 100% - 15px )",
    borderBottom: "1px solid #000",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    padding: "15px 25px",
    flexWrap: "wrap",
  },
  item: {
    width: "50%",
    fontSize: 12,
  }
});

export default ({ heading, data }) => {

  if (data && data.length > 0)
    return (
      <>
        <View>
          <Text style={styles.heading}>{heading}</Text>
          <View style={styles.divider}>
            <View style={styles.line}></View>
          </View>

          <View style={styles.section}>
            {data.map((d, i) => <Fragment key={i}>
              <Text style={styles.item}>&#8226; {d.title}</Text>
            </Fragment>)}
          </View>
        </View>
      </>
    );
  else return null;
}