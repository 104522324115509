import { Row } from "react-bootstrap";
import ExpCard from "./ExpCard";
import { useState } from "react";


const ExperienceSec = ({ experiences, setExperiences, handleSave }) => {

  const [showExperience, setShowExperience] = useState(0);

  const addExp = () => {
    const oldKey = experiences.length > 0 ? Math.max(...experiences.map(o => o.key)) : 0;
    setExperiences(old => [...old, {
      key: oldKey + 1,
      organization: "",
      place: "",
      designation: "",
      from: "",
      to: "",
      responsibility: "",
      isCurrent: false,
    }]);
    setShowExperience(oldKey + 1);
  }

  const onDelete = (key) => {
    setExperiences(data => data.filter(exp => exp.key != key));
  }

  const onUpdate = (newData, key) => {
    setExperiences(data => data.map(oldData => oldData.key === key ? newData : oldData));
  }

  return (
    <Row className="mb-5">
      <div className="sub-title">Employment History</div>
      <p className="sub-title-desc mb-3">Show your relevant experience (last 10 years). Use bullet points to note your achievements, if possible - use numbers/facts (Achieved X, measured by Y, by doing Z).</p>

      {experiences.map((exp, i) => {
        return (<ExpCard
          key={exp.key}
          exp={exp}
          onDelete={onDelete}
          onUpdate={onUpdate}
          showExperience={showExperience}
          setShowExperience={setShowExperience}
          handleSave={handleSave}
        />)
      }
      )}

      <div className="d-flex justify-content-start fables-second-text-color">
        <span onClick={() => addExp()}>
          <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <polygon points="13 11 17 11 17 13 13 13 13 17 11 17 11 13 7 13 7 11 11 11 11 7 13 7"></polygon>
          </svg>
          {experiences.length > 0 ? "Add one more employment" : "Add employment"}
        </span>
      </div>
    </Row>
  );
}

export default ExperienceSec;