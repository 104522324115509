import { useState } from "react";
import { Row } from "react-bootstrap";
import ProCard from "./ProCard";


const ProjectSec = ({ title, addText, showDescription = true, projects, setProjects, handleSave }) => {
  const [showProject, setShowProject] = useState(0);

  const add = () => {
    const oldKey = projects.length > 0 ? Math.max(...projects.map(o => o.key)) : 0;
    setProjects(old => [...old, {
      key: oldKey + 1,
      name: "",
      details: "",
    }]);
    setShowProject(oldKey + 1);
  }

  const onDelete = (key) => {
    setProjects(data => data.filter(obj => obj.key != key));
  }

  const onUpdate = (newData, key) => {
    setProjects(data => data.map(oldData => oldData.key === key ? newData : oldData));
  }


  return (
    <Row className="mb-5">
      <div className="sub-title mb-3">{title}</div>

      {projects.map((pro, i) =>
        <ProCard
          key={pro.key}
          pro={pro}
          onDelete={onDelete}
          onUpdate={onUpdate}
          showProject={showProject}
          setShowProject={setShowProject}
          addText={addText}
          showDescription={showDescription}
          handleSave={handleSave}
        />)}

      <div className="d-flex justify-content-start fables-second-text-color">
        <span onClick={() => add()}>
          <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <polygon points="13 11 17 11 17 13 13 13 13 17 11 17 11 13 7 13 7 11 11 11 11 7 13 7"></polygon>
          </svg>
          {projects.length > 0 ? "Add one more " + addText : "Add " + addText}
        </span>
      </div>
    </Row>
  );
}

export default ProjectSec;