

const Loader = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: "auto", background: "transparent", display: "block", shapeRendering: "auto" }} width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" fill="none" stroke="#c80000" stroke-width="7" r="31" stroke-dasharray="146.08405839192537 50.69468613064179">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  );
}

export default Loader;