const SESSION = process.env.REACT_APP_SESSION || "session";
const ADMIN_SESSION = process.env.REACT_APP_ADMIN_SESSION || "admin";

export const getUserId = () => {
  return localStorage.getItem(SESSION);
}

export const setUserId = id => {
  localStorage.setItem(SESSION, id)
}

export const removeUserId = () => {
  localStorage.removeItem(SESSION);
}

export const getAdminId = () => {
  return sessionStorage.getItem(ADMIN_SESSION);
}

export const setAdminId = id => {
  sessionStorage.setItem(ADMIN_SESSION, id);
}

export const removeAdminId = () => {
  sessionStorage.removeItem(ADMIN_SESSION);
}