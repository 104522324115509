const Footer = () => {

  return (
    <div className="fables-footer-image fables-after-overlay white-color py-4 py-lg-5 bg-rules">
      <div className="container">
        <div className="row">
          {/* <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-2 mb-5 text-center">
            <h2 className="font-30 semi-font mb-5">Newsletter</h2>
            <form className="form-inline position-relative">
              <div className="form-group fables-subscribe-formgroup">
                <input type="email" className="form-control fables-subscribe-input fables-btn-rouned" placeholder="Your Email" />
              </div>
              <button type="submit" className="btn fables-second-background-color fables-btn-rouned fables-subscribe-btn">Subscribe</button>
            </form>
          </div> */}
          <div className="col-12 col-lg-4 mb-4 mb-lg-0">
            <a href="#" className="fables-second-border-color border-bottom pb-3 d-block mb-3 mt-minus-13">
              <img src="/images/logo.png" alt="Logo" className="fables-logo" />
            </a>
            <p className="font-15 fables-third-text-color">
              We are committed to helping you achieve your career goals and reach your full potential. Join us and take the first step towards a fulfilling and successful career!"
            </p>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <h2 className="font-20 semi-font fables-second-border-color border-bottom pb-3">Contact us</h2>
            <div className="my-3">
              <h4 className="font-16 semi-font"><span className="fables-iconmap-icon fables-second-text-color pr-2 font-20 mt-1 d-inline-block"></span> Address Information</h4>
              <p className="font-14 fables-fifth-text-color mt-2 ml-4">Sheika Noora Tower, Ineternet City, Dubai,<br /> United Arab Emirates</p>
            </div>
            <div className="my-3">
              <h4 className="font-16 semi-font"><span className="fables-iconphone fables-second-text-color pr-2 font-20 mt-1 d-inline-block"></span> Call Now </h4>
              <p className="font-14 fables-fifth-text-color mt-2 ml-4">+971 55 796 35 34</p>
            </div>
            <div className="my-3">
              <h4 className="font-16 semi-font"><span className="fables-iconemail fables-second-text-color pr-2 font-20 mt-1 d-inline-block"></span> Mail </h4>
              <p className="font-14 fables-fifth-text-color mt-2 ml-4">careercatalystdxb@gmail.com</p>
            </div>
          </div>
          {/* <div className="col-12 col-sm-6 col-lg-4">
            <h2 className="font-20 semi-font fables-second-border-color border-bottom pb-3 mb-3">EXPLORE OUR SITE</h2>
            <ul className="nav fables-footer-links">
              <li><a href="about1.html">About Us</a></li>
              <li><a href="contactus1.html">Contact Us</a></li>
              <li><a href="gallery.html">Gallery</a></li>
              <li><a href="team.html">Team</a></li>
              <li><a href="blog.html">Blog</a></li>
              <li><a href="testimonials.html">Testimonials</a></li>
            </ul>
          </div> */}

        </div>

      </div>
    </div>
  );
}

export default Footer;