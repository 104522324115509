import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';

const ContentSec = () => {

  const Anim = ({ title, number }) => <Col sm={6} md={4} className="mb-5" >
    <h2 className='mb-3'><CountUp end={number} enableScrollSpy={true} scrollSpyOnce={true} />+</h2>
    <div className="dropdown-divider mb-3"></div>
    <p className="content-name">{title}</p>
  </Col>

  return (
    <Container>
      <Row className="mb-5 mt-5">
        <Col lg={12}>
          <div className="mb-5">
            <h2 className="" style={{ lineHeight: 2 }}>Premium Coaching Like No Other!</h2>
            <p style={{ lineHeight: 1.75 }}>
              We offer one-on-one services to help you be the best version of yourself while applying for a job. With personalized services for curating your job application, you can make sure you are ready to step into the industry with your best foot forward. Career Catalyst's straightforward and transparent approach will guide you towards being the best version of yourself and landing that dream job.
            </p>
          </div>
          <Row className="d-flex justify-content-center text-center">
            <Anim title="Resume Download" number={300} />
            <Anim title="Cover Letter" number={250} />
            <Anim title="Interview Notes" number={100} />
          </Row>
        </Col>
        {/* <Col lg={6} className="d-flex justify-content-center">
          <img src="/images/content_img.png" alt="img" style={{ width: "85%" }} />
        </Col> */}
      </Row>
    </Container>
  );
}

export default ContentSec;