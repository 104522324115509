import { Link } from "react-router-dom";
import { useState } from "react";
import BuildResumeModal from "./BuildResumeModal";


const VideoSection = () => {
  const [showBuildResume, setShowBuildResume] = useState(false);

  const closeBuildResume = () => setShowBuildResume(false);

  return (
    <section className="content-section video-section fables-after-overlay" style={{ position: "relative" }}>
      {/* <video src={"/videos/background.mp4"} className="player" autoPlay loop muted style={{ maxWidth: "100%", minHeight: "340px" }} /> */}
      <img src="/images/banner.png" className="player" style={{ maxWidth: "100%", minHeight: "450px", objectFit: "cover" }} />
      <div className="pattern-overlay" style={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
        position: "absolute",
        top: 0,
        left: 0,
      }}>
        {/* <a id="bgndVideo" className="player" data-property="{videoURL:'https://www.youtube.com/watch?v=fdJc1_IBKJA',containment:'.video-section', quality:'large',mute: 'true' ,autoPlay:true, opacity:1}">bg</a> */}
        <div className="container position-relative z-index">
          <div className="fables-video-caption text-center wow zoomIn" data-wow-delay="1s" data-wow-duration="2s">
            <h1 className="white-color font-weight-bold mt-6">Land your dream job with the help of our resume builder</h1>
            <p className="fables-fifth-text-color mt-3 mb-4 font-18">
              {`Get inspired by resume samples from professional resume experts`}
            </p>
            <div className="row d-flex justify-content-center">
              <Link to="/review" className="review-resume">
                Review Resume
              </Link>
              <Link to="#" className="review-resume" onClick={() => setShowBuildResume(true)}>
                Build Resume {/*<span className="fables-iconplay fables-second-text-color font-weight-bold wow bounce ml-3"></span> */}
              </Link>
            </div>
          </div>
        </div>
      </div>
      {showBuildResume ?
        <BuildResumeModal closeModal={closeBuildResume} />
        : null
      }
    </section>
  );
}

export default VideoSection;