// import dynamic from 'next/dynamic';
import OwlCarousel from "react-owl-carousel";

// var $ = require("jquery");
// if (typeof window !== "undefined") {
//   window.$ = window.jQuery = require("jquery");
// }

// const OwlCarousel = dynamic(() => import("react-owl-carousel"), {
//   ssr: false,
// });


const Reviews = () => {

  const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    items: 1,
    // navClass:['fables-iconarrow-left','fables-iconarrow-right'],
    navText: [`<span class="fables-iconarrow-left"></span>`, `<span class="fables-iconarrow-right"></span>`],
    navElement: "div",
  }

  const reviews = [
    {
      name: "Muhammed Fazih",
      image: "/custom/review_images/img_3.jpeg",
      designation: "Accountant, Osterio Mario, Dubai hills mall",
      review: "Using a career catalyst writing service was a game-changer for me. They took the time to deeply understand my background and career goals, then crafted a polished, tailored resume that highlighted my key qualifications. The final product was a significant upgrade from the resume I had written myself, and I believe it gave me a major advantage in my job search. While resume services come at a cost, I found the investment to be well worth it in terms of the quality of my application materials and the interviews I was able to secure."
    },
    {
      name: "Sreenithin",
      image: "/custom/review_images/img_1.JPG",
      designation: "Business Development Executive, Brillianz Education, Dubai",
      review: "Excellent Service"
    },
    {
      name: "Jitto Chacko",
      image: "/custom/review_images/img_2.jpeg",
      designation: "Accountant, Hometools Techinical Services, AI Quoz",
      review: "Excellent"
    },
  ]

  return (
    <div className="fables-testimonial fables-after-overlay py-4 py-md-5 my-4 my-lg-5 half-width-testimonial bg-rules">
      <div className="container z-index position-relative">
        <div className="row">
          <div className="col-12 col-md-12 wow fadeIn text-center" data-wow-duration="2.5s" data-wow-delay=".4s">
            <p className="white-color font-25 mb-2">See what</p>
            <h2 className="fables-second-text-color font-35 font-weight-bold">others  <span className="white-color">are saying</span></h2>
            <p className="fables-third-text-color mt-4 mb-4">
              We care, we take a personalized approach to each customer, and our management team is never more than a phone call or email away.
            </p>
            {/* <div className="owl-carousel owl-theme dots-0 nav-default" id="fables-testimonial-carousel"> */}
            <OwlCarousel className='owl-theme reviews-owl' id='fables-testimonial-carousel' {...options}>
              {reviews.map((review, i) =>
                <div className="text-center" key={i}>
                  <div>
                    <div className="image-container shine-effect d-inline-block rounded-circle">
                      <img
                        src={review.image}
                        alt="Fables Template"
                        className="fables-testimonial-carousel-img my-0 mx-auto rounded-circle"
                        style={{ height: "130px", width: "130px", objectFit: "cover" }}
                      />
                    </div>
                    <h3 className="font-14 semi-font  white-color mt-3">{review.name}</h3>
                    <h3 className="font-13 font-italic white-color mt-2 mb-4">{review.designation}</h3>
                  </div>
                  <div className="fables-testimonial-carousel-item fables-rounded p-3">
                    <div className="fables-testimonial-detail font-15 font-italic white-color position-relative">
                      {review.review}
                    </div>
                  </div>
                </div>
              )}


            </OwlCarousel>
          </div>
          {/* <div className="col-12 col-md-6 wow fadeIn mt-4 mt-md-5 mt-lg-0" data-wow-duration="2.5s" data-wow-delay=".4s">
            <div className="position-relative image-container zoomIn-effect">
              <img src="/custom/images/index-video.jpg" alt="" className="img-fluid" />
            </div>

          </div> */}
        </div>

      </div>
    </div>
  );
}

export default Reviews;