import logo from './logo.svg';
import './App.css';

// Test Import
import { GoogleOAuthProvider } from '@react-oauth/google';
import Base from './components/Base';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import store from './store';

// CSS
import './styles/common.css';
import './styles/admin.css';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/vendor/animate.css-master/animate.min.css';
import './styles/vendor/loadscreen/css/spinkit.css';
import './styles/vendor/fontawesome/css/fontawesome-all.min.css';
import './styles/custom/css/fables-icons.css';
import "./styles/vendor/bootstrap/css/bootstrap.min.css";
import "./styles/vendor/bootstrap/css/bootstrap-4-navbar.css";
import './styles/vendor/video-background/video-background.css';
import "./styles/custom/css/custom.css";
import "./styles/custom/css/custom-responsive.css";
import "./styles/style.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
      <Provider store={store}>
        <ToastContainer />
        <Base />
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
