import React, { useEffect, useState } from 'react';
import PreviousResume from './PreviousResume';


const PreviousResumeIndex = () => {

  return <>
    <div className="fables-header fables-after-overlay">
      <div className="container">
        <h2 className="fables-page-title fables-second-border-color">My Resumes</h2>
      </div>
    </div>
    <PreviousResume />
  </>
}

export default PreviousResumeIndex;