import { GoogleLogin, googleLogout } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getUserStart, loginStart } from "../../store/actions/userAction";
import { logout } from "../../store/slices/userSlice";
import { useEffect, useState } from 'react';
import { getUserId } from '../Auth';


const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails } = useSelector(state => state.user);
  const [showMenu, setShowMenu] = useState(false);

  const handleLogout = e => {
    e.preventDefault();
    googleLogout();
    dispatch(logout());
    navigate("/");
  }

  useEffect(() => {
    if (getUserId()) dispatch(getUserStart());
  }, []);

  return <div className="fables-navigation fables-main-background-color py-3 py-lg-0">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 col-lg-9 pr-md-0">
          <nav className="navbar navbar-expand-md btco-hover-menu py-lg-2">
            <Link className="navbar-brand pl-0" to="/">
              <img src="/images/logo.png" alt="Fables Template" className="fables-logo" />
            </Link>
            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#fablesNavDropdown" aria-controls="fablesNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="fables-iconmenu-icon text-white font-16"></span>
            </button>
            <div className="collapse navbar-collapse" id="fablesNavDropdown">
              <ul className="navbar-nav mx-auto fables-nav">
                <li className="nav-item">
                  <Link className="nav-link" href="/" >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Features
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" >
                    Contact Us
                  </a>
                </li>
              </ul>

            </div> */}
          </nav>
        </div>
        <div className="col-12 col-md-2 col-lg-3 pr-md-0 icons-header-mobile">
          {/* <div className="fables-header-icons"> */}
          {/* <div className="dropdown">
            <a href="#_" className="fables-third-text-color dropdown-toggle right px-3 px-md-2 px-lg-4 fables-second-hover-color top-header-link max-line-height position-relative" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="fables-iconcart-icon font-20"></span>
              <span className="fables-cart-number fables-second-background-color text-center">3</span>
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div className="p-3 cart-block">

              </div>
            </div>
          </div> */}
          {/* <a href="#" className="open-search fables-third-text-color right  top-header-link px-3 px-md-2 px-lg-4 fables-second-hover-color border-0 max-line-height">
              <span className="fables-iconsearch-icon"></span>
            </a> */}
          <div
            className="fables-third-text-color fables-second-hover-color font-13 top-header-link px-3 px-md-2 px-lg-4 max-line-height"
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            {Object.keys(userDetails.data).length > 0 ?
              <div className="dropdown">
                <a href="#" className="fables-third-text-color dropdown-toggle right fables-second-hover-color max-line-height position-relative" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img
                    onClick={e => { e.preventDefault(); setShowMenu(!showMenu) }}
                    style={{ width: "40px", borderRadius: "50%" }}
                    src={userDetails.data.user.picture}
                    alt={userDetails.data.user.name}
                  />
                </a>
              </div>
              : <GoogleLogin
                onSuccess={credentialResponse => {
                  console.log(credentialResponse);
                  const decoded = jwt_decode(credentialResponse.credential);
                  console.log(decoded);
                  dispatch(loginStart({
                    name: decoded.name,
                    picture: decoded.picture,
                    social_unique_id: decoded.sub,
                    login_type: "google",
                    email: decoded.email,
                  }));
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
                type="icon"
                useOneTap={true}
                shape={"pill"}
                theme={"outline"}
              />
            }
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
    {Object.keys(userDetails.data).length > 0 ?
      <div className="dropdown-menu nav-custom-menu" aria-labelledby="dropdownMenuButton" style={{ display: showMenu ? "block" : "none" }}>
        <div className="p-3 cart-block" onMouseLeave={() => setShowMenu(false)}>
          <p className="username">{userDetails.data.user.name}</p>
          <div className="dropdown-divider"></div>
          <Link className="dropdown-item" to="/my-resumes" style={{ lineHeight: "20px" }}>My Resumes</Link>
          <button className="dropdown-item" href="#" style={{ lineHeight: "20px" }} onClick={handleLogout}>Logout</button>
        </div>
      </div>
      : null
    }
  </div>
}

export default Header;