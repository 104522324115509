import { createAsyncThunk } from '@reduxjs/toolkit';
import api from "./API";

export const loginStart = createAsyncThunk("user/login", async data => {
  const res = await api.get({ url: "signup.php", data });
  return res.data;
});

export const getUserStart = createAsyncThunk("user/get-user", async data => {
  const res = await api.get({ url: "get-user.php", data });
  return res.data;
})

export const createResumeStart = createAsyncThunk("user/create-resume", async data => {
  const res = await api.get({ url: "create-resume.php", data });
  return res.data;
})

export const getResumeDataStart = createAsyncThunk("user/get-resume-data", async data => {
  const res = await api.get({ url: "get-resume-data.php", data });
  return res.data;
})

export const updateResumeStart = createAsyncThunk("user/update-resume-data", async data => {
  const res = await api.post({ url: "update-resume.php", data });
  return res.data;
})

export const resumeListStart = createAsyncThunk("user/resume-list", async data => {
  const res = await api.get({ url: "get-resumes-list.php", data });
  return res.data;
})

export const generateStripeStart = createAsyncThunk("user/generate-stripe", async data => {
  const res = await api.post({ url: "get-stripe-payment-details.php", data });
  return res.data;
})

export const confirmPaymentStart = createAsyncThunk("user/confirm-payment", async data => {
  const res = await api.get({ url: "update-payment.php", data });
  return res.data;
})

export const updateProfilePicStart = createAsyncThunk("user/update-profile", async data => {
  const res = await api.postMedia({ url: "upload-profile.php", data });
  return res.data;
})

export const updateScreenShotStart = createAsyncThunk("user/update-screenshot", async data => {
  const res = await api.postMedia({ url: "update-resume-snapshot.php", data });
  return res.data;
})

// Admin

export const getAllOrders = createAsyncThunk("admin/all-orders", async data => {
  const res = await api.get({ url: "all-orders.php", data });
  return res.data;
})