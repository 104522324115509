import React from 'react';

const NoPage = () => {

  return (
    <div style={{ minHeight: "calc( 100vh - 75px )", color: "#c80000" }} className="d-flex justify-content-center align-items-center">
      <h1>404 <span style={{ fontSize: "20px", color: "#222" }}>Oops Page not found...</span></h1>
    </div>
  );
}

export default NoPage;