import { getErrorNotification, getSuccessNotification } from "../components/Utils/Notification";

export const getInitialData = (data = {}) => ({
  data,
  loading: true,
  buttonDisable: false,
  error: {},
})

export const getPendingData = (data = {}) => ({
  data,
  loading: true,
  buttonDisable: true,
  error: {},
  message: null,
})

export const getFulfilledData = ({
  payload,
  error,
  successNotify = true,
  errorNotify = true,
}) => {
  if (payload.status) {
    if (successNotify) getSuccessNotification(payload.message);
    return {
      data: payload.data,
      loading: false,
      buttonDisable: false,
      error: {},
      message: payload.message
    }
  } else {
    if (errorNotify) getErrorNotification(payload.message ? payload.message : error.message);
    return {
      data: {},
      loading: false,
      buttonDisable: false,
      error: payload,
      message: payload.message,
    }
  }
}

export const getFailureData = ({ error = {}, errorNotify = true }) => {
  if (errorNotify) getErrorNotification(error.message ? error.message : "Something went wrong");
  return {
    data: {},
    loading: false,
    buttonDisable: false,
    error
  }
}