import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getErrorNotification } from "../Utils/Notification";
import { setAdminId } from "../Auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();


  const handleSubmit = e => {
    e.preventDefault();
    if (username === "admin@careercatalyst.com" && password === "Admin@789") {
      setAdminId(1123);
      navigate("/admin"); 
    }
    else getErrorNotification("Invalid Credentials");
  }

  return <div className="admin-login">
    <div className="login-container">
      <div className="header">
        Login
      </div>
      <div className="body">
        <form onSubmit={handleSubmit} >
          <Row className="justify-content-center">
            <Col md={8}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control className="common" type="email" autoFocus={true} value={username} onChange={e => setUsername(e.target.value)} />
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control className="common" type="password" value={password} onChange={e => setPassword(e.target.value)} />
              </Form.Group>
            </Col>
            <Col md={8}>
              <button type="submit" className="login-btn float-end">Login</button>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  </div>
}

export default Login;