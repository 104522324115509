import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import Header from './Header';
import Experience from './Experience';
import Education from './Education';
import Skill from './Skill';
import Project from './Project';

const Template1 = ({ data }) => {
  const styles = StyleSheet.create({
    document: {
      padding: 30,
      width: "100%",
    },
    page: {
      padding: 30,
    },
    container: {
      flex: 1,
      flexDirection: 'row',
      '@media max-width: 400': {
        flexDirection: 'column',
      },
    },
    image: {
      marginBottom: 10,
    },
    leftColumn: {
      flexDirection: 'column',
      width: 170,
      paddingTop: 30,
      paddingRight: 15,
      '@media max-width: 400': {
        width: '100%',
        paddingRight: 0,
      },
      '@media orientation: landscape': {
        width: 200,
      },
    },
    footer: {
      fontSize: 12,
      // fontFamily: 'Lato Bold',
      textAlign: 'center',
      marginTop: 15,
      paddingTop: 5,
      borderWidth: 3,
      borderColor: 'gray',
      // borderStyle: 'dashed',
      '@media orientation: landscape': {
        marginTop: 10,
      },
    },
  });

  return <>
    <Document style={styles.document}>
      <Page size="A4" style={styles.page}>
        <Header data={data} />
        <Experience data={data.experiences} />
        <Education data={data.educations} />
        <Project data={data.projects}/>
        <Skill heading={"Skills"} data={data.skills} />
        <Skill heading={"Intrests"} data={data.intrests} />
        {/* <Text style={styles.footer}>This IS the candidate you are looking for</Text> */}
      </Page>
    </Document>
  </>
}

export default Template1;