import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Features = () => {

  const motionOptions = {
    variants: {
      offscreen: {
        y: 100,
        opacity: 0,
      },
      onscreen: {
        y: 0,
        opacity: 1,
        transition: {
          type: "bounce",
          bounce: 0.8,
          duration: 1,
        }
      }
    },
    initial: "offscreen",
    whileInView: "onscreen",
    viewport: { once: true, amount: 0.8 }
  }

  return (
    <div className="container">
      <div className="row my-4 my-md-5">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
          <h2 className="font-35 font-weight-bold fables-second-text-color mb-4">Our Services</h2>
          <p className="fables-forth-text-color">
            We have End-to-end solutions for your career. we have a specialized team to check your capabilities & walk you through.
          </p>
        </div>
        <motion.div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5" {...motionOptions}>
          <div className="row text-center text-md-left">
            <div className="col-12 col-md-3">
              <span className="fa fa-file-alt fables-second-text-color fa-3x"></span>
            </div>
            <div className="col-12 col-md-9 pl-md-0">
              <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Free Resume Review</h2>
              <div className="font-15 fables-forth-text-color">
                You can check your current resume weather your resume following Resume Standards and ATS friendly
              </div>

            </div>
          </div>
        </motion.div>
        <motion.div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5" {...motionOptions}>
          <div className="row text-center text-md-left">
            <div className="col-12 col-md-3">
              <span className="fa fa-pencil-alt fables-second-text-color fa-3x"></span>
            </div>
            <div className="col-12 col-md-9 pl-md-0">
              <Link to="/list">
                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Make Your Own Resume</h2>
              </Link>
              <div className="font-15 fables-forth-text-color">
                Create your own resume, from our dozens of templates.
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5" {...motionOptions}>
          <div className="row text-center text-md-left">
            <div className="col-12 col-md-3">
              <span className="fa fa-envelope fables-second-text-color fa-3x"></span>
            </div>
            <div className="col-12 col-md-9 pl-md-0">
              <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Cover Letter</h2>
              <div className="font-15 fables-forth-text-color">
                We will create a personalized cover letter, According to your Job and experience
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5" {...motionOptions}>
          <div className="row text-center text-md-left">
            <div className="col-12 col-md-3">
              <span className="fa fa-user fables-second-text-color fa-3x"></span>
            </div>
            <div className="col-12 col-md-9 pl-md-0">
              <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">LinkedIn Profile Makeover</h2>
              <div className="font-15 fables-forth-text-color">
                We have a specially trained team to handle LinkedIn Profiles and for your better professional career
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5" {...motionOptions}>
          <div className="row text-center text-md-left">
            <div className="col-12 col-md-3">
              <span className="fa fa-book fables-second-text-color fa-3x"></span>
            </div>
            <div className="col-12 col-md-9 pl-md-0">
              <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Ebooks for interview preparations</h2>
              <div className="font-15 fables-forth-text-color">
                We have pre-prepared Interview Questions and Answers, Created by our research team
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5" {...motionOptions}>
          <div className="row text-center text-md-left">
            <div className="col-12 col-md-3">
              <span className="fa fa-share-alt fables-second-text-color fa-3x"></span>
            </div>
            <div className="col-12 col-md-9 pl-md-0">
              <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Resume Distributions</h2>
              <div className="font-15 fables-forth-text-color">
                We will distribute your Resume to companies, which is suitable for your job Title and Experience
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      <h2 className="font-35 font-weight-bold text-center fables-second-text-color">Company</h2>
      <div className="row mt-3 mt-lg-5">
        <div className="col-12 col-lg-6 mt-3 mt-lg-0 overflow-hidden">
          <motion.div
            initial={{ opacity: 0, x: -150 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
            viewport={{ once: true }}
          >
            <p className="fables-main-text-color font-25 mb-2 wow fadeInLeft">WHY</p>
            <h2 className="fables-second-text-color font-35 font-weight-bold wow fadeInLeft">CHOOSE  <span className="fables-main-text-color">US</span></h2>
            <p className="fables-forth-text-color mt-4 mb-4">
              We Career Catalyst are dedicated to serving our clients in their professional careers in all manner. Our well-trained and professional team monitors the Job market every
              time to put you in a good position. So why wait? Join Us. Give your a boost.
            </p>
          </motion.div>
          <motion.div
            className="position-relative mb-3 wow bounceInDown"
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            viewport={{ once: true }}
          >
            <p className="fables-fifth-text-color fables-dots-text">
              <span className="fables-dots-circle fables-second-before fables-second-border-color"></span>
              Easy online resume builder
            </p>
          </motion.div>
          <motion.div className="position-relative mb-3 wow bounceInDown"
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.6 }}
            viewport={{ once: true }}
          >
            <p className="fables-fifth-text-color fables-dots-text">
              <span className="fables-dots-circle fables-second-before fables-second-border-color"></span>
              A well-trained team for your support
            </p>
          </motion.div>
          <motion.div className="position-relative mb-3 wow bounceInDown"
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.9 }}
            viewport={{ once: true }}
          >
            <p className="fables-fifth-text-color fables-dots-text">
              <span className="fables-dots-circle fables-second-before fables-second-border-color"></span>
              Interview preparation Documents from a professional team
            </p>
          </motion.div>
          <motion.div className="position-relative mb-3 wow bounceInDown"
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 1.2 }}
            viewport={{ once: true }}
          >
            <p className="fables-fifth-text-color fables-dots-text">
              <span className="fables-dots-circle fables-second-before fables-second-border-color"></span>
              Free checking of your Resume
            </p>
          </motion.div>


        </div>
        <motion.div className="col-12 col-lg-6"
          initial={{ opacity: 0, x: 150 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 1.5 }}
          viewport={{ once: true }}
        >
          <div className="together-block">
            <div className="fables-main-color-transparent p-4 p-lg-5 position-relative">
              <img src="/custom/images/man-image.png" alt="" className="img-fluid together-abs-img wow fadeIn" />
              <p className="white-color font-17 mb-2">{`Let's Hunt Job`}</p>
              <h2 className="white-color font-30 semi-font">Together</h2>
              <p className="white-color font-14 together-details my-3">
                We have dozens of templates that looks cool & ATS Friendly. So Create One & Get Hired.
              </p>
              <Link to="/list" className="btn fables-second-background-color white-color white-color-hover fables-btn-rounded py-2 px-5 my-0 my-md-4">Create Resume </Link>
            </div>
          </div>

        </motion.div>
      </div>
    </div>
  );
}

export default Features;