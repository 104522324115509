import React, { Fragment } from 'react';
import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';


const Skill = ({ data, padding, heading }) => {

  const styles = StyleSheet.create({
    container: {
      paddingLeft: padding,
      paddingRight: padding,
      marginBottom: "20px",
      fontFamily: "Baskerville Old Face",
    },
    heading: {
      marginBottom: "8px",
      color: "#000",
    },
    section: {
      display: "flex",
      flexDirection: "row",
      padding: "15px 25px",
      flexWrap: "wrap",
    },
    item: {
      width: "50%",
      fontSize: 10,
      marginBottom: "8px",
    }
  });

  if (data && data.length > 0)
    return (
      <View style={styles.container}>
        <Text style={styles.heading}>{heading}</Text>
        <View style={styles.section}>
          {data.map((d, i) => <Fragment key={i}>
            <Text style={styles.item}>{d.title}</Text>
          </Fragment>)}
        </View>
      </View>
    );
  else return null;

}

export default Skill;