const Copyright = () => {

  return (
    <div className="copyright fables-main-background-color mt-0 border-0 white-color">
      <ul className="nav fables-footer-social-links just-center fables-light-footer-links">
        <li><a href="#" target="_blank"><i className="fab fa-google-plus-square"></i></a></li>
        <li><a href="#" target="_blank"><i className="fab fa-facebook"></i></a></li>
        <li><a href="#" target="_blank"><i className="fab fa-linkedin"></i></a></li>
        <li><a href="https://instagram.com/careercatalystdxb" target="_blank"><i className="fab fa-instagram"></i></a></li>
        {/* <li><a href="#" target="_blank"><i className="fab fa-pinterest-square"></i></a></li>
        <li><a href="#" target="_blank"><i className="fab fa-twitter-square"></i></a></li> */}
      </ul>
      <p className="mb-0">Copyright © Career Catalyst 2023. All rights reserved.</p>

    </div>
  );
}

export default Copyright;