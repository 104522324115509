import { Document, Font, Page, StyleSheet } from "@react-pdf/renderer";
import Header from "./Header";
import Experiecnce from "./Experience";
import Education from "./Education";
import Project from "./Project";
import Skill from "./Skill";


const Template2 = ({ data }) => {

  const padding = "70px"

  const styles = StyleSheet.create({
    document: {
      padding: 30,
      width: "100%",
    },
    page: {
      // padding: 96,
      paddingTop: padding,
      paddingBottom: padding,
    },
  });

  Font.register({
    family: 'Baskerville Old Face',
    src: `./Fonts/baskerville_old_face/BASKVILL.TTF`,
  });

  Font.register({
    family: 'Corbel',
    src: `./Fonts/corbel/corbel.ttf`,
  });

  Font.register({
    family: "Courier New",
    src: "./Fonts/courier_new/cour.ttf"
  })

  return <>
    <Document style={styles.document}>
      <Page size="A4" style={styles.page}>
        <Header data={data} padding={padding} />
        <Experiecnce data={data.experiences} padding={padding} />
        <Education data={data.educations} padding={padding} />
        <Project data={data.projects} padding={padding} />
        <Skill data={data.skills} padding={padding} heading="SKILL" />
        <Skill data={data.intrests} padding={padding} heading="INTREST" />
      </Page>
    </Document>
  </>
}

export default Template2;