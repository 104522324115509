import { Button, Col, Container, Form, Modal, Row, } from "react-bootstrap";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeSec from "./StripeSec";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);


const PaymentModal = ({ closeModal, data, onSuccess, secret }) => {

  // const downloadPdfDocument = (rootElementId) => {
  //   const input = document.getElementById(rootElementId);
  //   html2canvas(input)
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF();
  //       pdf.addImage(imgData, 'JPEG', 0, 0);
  //       pdf.save("resume.pdf");
  //     })
  //   // const doc = new jsPDF({
  //   //   format: 'a4',
  //   //   // unit: 'px'
  //   //   // unit: "in",

  //   // });

  //   // // Adding the fonts.
  //   // // doc.setFont('Inter-Regular', 'normal');

  //   // doc.html(input, {
  //   //   async callback(doc) {
  //   //     await doc.save('document');
  //   //   },
  //   // });
  // }

  const options = {
    clientSecret: secret,
  };

  return (
    <Modal
      className='payment-modal'
      show={true}
      onHide={closeModal}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="col-11 text-center fables-second-text-color">
          Payment - 20Rs
        </Modal.Title>
      </Modal.Header>
      <Container>
        <Modal.Body>
          {/* <div className="fables-second-background-color pb-5 pt-5 mb-3">Payment Methods</div> */}
          {/* <Row>
          <Col xs={12} className="mb-3">
            <Form.Control placeholder="Name on Card" />
          </Col>
          <Col xs={12} className="mb-3">
            <Form.Control placeholder="Card Number" />
          </Col>
          <Col md={4} className="mb-3">
            <Form.Control placeholder="MM" />
          </Col>
          <Col md={4} className="mb-3">
            <Form.Control placeholder="YYYY" />
          </Col>
          <Col md={4}>
            <Form.Control placeholder="CVV" />
          </Col>
        </Row> */}
          <Elements stripe={stripePromise} options={options}>
            <StripeSec secret={secret} onSuccess={onSuccess} />
          </Elements>

        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={closeModal}>Close</Button> */}
          {/* <button className="btn fables-second-background-color w-100" onClick={() => handlePayment()} style={{ padding: "10px 30px", color: "#fff" }}>Pay</button> */}
        </Modal.Footer>
      </Container>
    </Modal>
  );
}

export default PaymentModal;