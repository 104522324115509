import { Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../store/slices/userSlice";
import { useEffect, useState } from "react";
import UserDetailsSec from "./UserDetailsSec";
import ExperienceSec from "./ExperienceSec";
import EducationSec from "./EducationSec";
import LivePreview from "./LivePreview";
import ProjectSec from "./ProjectSec";
import PageLoader from "../Utils/PageLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getResumeDataStart, updateProfilePicStart, updateResumeStart, updateScreenShotStart } from "../../store/actions/userAction";
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";


const PreviewIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { getResumeData, updateProfilePic } = useSelector(state => state.user);
  const [skipRender, setSkipRender] = useState(true);
  const [count, setCount] = useState(0);
  const [lastUpdateData, setLastUpdateData] = useState({});
  const [image, setImage] = useState({
    profile: null,
  })
  const [basicDetails, setBasicDetails] = useState({
    title: "Untitled",
    name: "",
    email: "",
    mobile: "",
    designation: "",
    description: "",
  });
  const [educations, setEducations] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [projects, setProjects] = useState([]);
  const [skills, setSkills] = useState([]);
  const [intrests, setIntrests] = useState([]);

  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (searchParams.get('id')) {
      dispatch(getResumeDataStart({ resume_id: searchParams.get('id') }));
    } else {
      navigate("/");
    }
  }, [searchParams]);

  useEffect(() => {
    if (!skipRender && !getResumeData.loading && Object.keys(getResumeData.data).length > 0) {
      if (getResumeData.data.resume.resume_data) {
        const data = JSON.parse(getResumeData.data.resume.resume_data);
        setEducations(data.educations);
        setExperiences(data.experiences);
        setProjects(data.projects);
        setSkills(data.skills);
        setIntrests(data.intrests);
        setBasicDetails({
          title: data.title,
          name: data.name,
          email: data.email,
          mobile: data.mobile,
          designation: data.designation,
          description: data.description,
        })
        setLastUpdateData({
          title: data.title,
          name: data.name,
          email: data.email,
          mobile: data.mobile,
          designation: data.designation,
          description: data.description,
          educations: data.educations,
          experiences: data.experiences,
          projects: data.projects,
          skills: data.skills,
          intrests: data.intrests,
        })
      }
      setImage({
        profile: getResumeData.data.resume.resume_user_image,
      })
    }
  }, [getResumeData]);

  const handleSave = () => {
    const data = {
      resume_id: searchParams.get('id'),
      ...basicDetails,
      experiences,
      educations,
      projects,
      skills,
      intrests
    };
    if (JSON.stringify(lastUpdateData) != JSON.stringify(data)) {
      setLastUpdateData(data);
      dispatch(updateResumeStart(data));
      saveScreenShot();
    }
  }

  const saveScreenShot = () => {
    setTimeout(() => {
      const node = document.getElementById("preview");
      console.log(node);
      htmlToImage.toPng(node)
        .then(function (dataUrl) {
          const uploadFile = dataURItoBlob(dataUrl);
          dispatch(updateScreenShotStart({
            resume_id: searchParams.get('id'),
            fileToUpload: uploadFile,
          }));
        })
        .catch(function (error) {
          console.error('oops, something went wrong!');
          console.log(error)
        });
    }, 1000);
  }

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const handleFileChange = e => {
    const file = e.target.files[0];
    dispatch(updateProfilePicStart({
      resume_id: searchParams.get('id'),
      fileToUpload: file,
    }));
    e.target.value = null;
  }

  useEffect(() => {
    if (!skipRender && !updateProfilePic.loading && Object.keys(updateProfilePic.data).length > 0) {
      setImage({
        ...image,
        profile: updateProfilePic.data.picture
      })
    }
    setSkipRender(false);
  }, [updateProfilePic]);

  return (
    <Container fluid id="p">
      {getResumeData.loading ?
        <PageLoader />
        : Object.keys(getResumeData.data).length > 0 ?
          <Form className="user-details" onSubmit={e => e.preventDefault()}>
            <Row style={{ minHeight: "calc( 100vh - 75px )" }} className="d-flex justify-content-center">
              <Col md={10} lg={8} xl={6} className="px-5 pt-5 user-form mb-lg-5 mb-xl-0">
                <Container className="no-hide">
                  <input
                    type="text"
                    className="text-center mb-3 form-title w-100"
                    value={basicDetails.title}
                    onChange={e => setBasicDetails({ ...basicDetails, title: e.target.value })}
                    onBlur={handleSave}
                  />
                  <UserDetailsSec
                    values={basicDetails}
                    setFieldValue={setBasicDetails}
                    image={image}
                    handleFileChange={handleFileChange}
                    handleSave={handleSave}
                  />
                  <ExperienceSec
                    experiences={experiences}
                    setExperiences={setExperiences}
                    handleSave={handleSave}
                  />
                  <EducationSec
                    educations={educations}
                    setEducations={setEducations}
                    handleSave={handleSave}
                  />
                  <ProjectSec
                    title="Projects"
                    addText="project"
                    projects={projects}
                    setProjects={setProjects}
                    handleSave={handleSave}
                  />
                  <ProjectSec
                    title="Skills"
                    addText="skill"
                    projects={skills}
                    setProjects={setSkills}
                    showDescription={false}
                    handleSave={handleSave}
                  />
                  <ProjectSec
                    title="Intrests"
                    addText="intrest"
                    projects={intrests}
                    setProjects={setIntrests}
                    showDescription={false}
                    handleSave={handleSave}
                  />


                </Container>
              </Col>
              <LivePreview
                data={{
                  ...lastUpdateData,
                  ...image,
                }}
                resume_id={searchParams.get('id')}
                showPreview={showPreview}
                template_id={getResumeData.data.resume.template_id}
              />
            </Row>
            <button className="preview-btn" onClick={() => setShowPreview(!showPreview)}>{showPreview ? "Close" : "Preview"}</button>
          </Form>
          : null
      }
    </Container >
  );
}

export default PreviewIndex;