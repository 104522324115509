import { Card, Collapse, Row, Col, Form } from "react-bootstrap";

const ProCard = ({ pro, addText, showDescription, onDelete, onUpdate, showProject, setShowProject, handleSave }) => {

  const updateData = e => {
    e.preventDefault();
    onUpdate({
      ...pro,
      [e.target.name]: e.target.value
    }, pro.key)
  }

  const Field = ({ name, className = "", placeholder = "", ...props }) => <Form.Control
    id={"pro" + name}
    name={name}
    className={className}
    value={pro[name]}
    onChange={updateData}
    placeholder={placeholder}
    {...props}
    onBlur={handleSave}
  />

  return (
    <Card className="mb-3 c">
      <div className="c-header" onClick={() => setShowProject(showProject === pro.key ? 0 : pro.key)}>
        <div className="c-title">
          {pro.title ?
            <>{pro.title}</>
            : "(Not specified)"
          }
        </div>
        <div style={{ rotate: showProject === pro.key ? "-90deg" : "90deg", transitionDuration: "0.5s" }}>
          <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path d="M9.431 7.257l1.352-1.474 5.893 5.48a1 1 0 0 1 0 1.474l-5.893 5.45-1.352-1.475L14.521 12 9.43 7.257z"></path>
          </svg>
        </div>
      </div>
      <div className="c-delete" onClick={() => onDelete(pro.key)}>
        <i className="fa fa-trash" aria-hidden="true"></i>
      </div>
      <Collapse in={showProject === pro.key}>
        <div className={`c-body`}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>{addText} Name</Form.Label>
                {Field({ name: "title", className: "common" })}
              </Form.Group>
            </Col>
            {showDescription ?
              <Col xs={12} className="mb-3">
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  {Field({ name: "description", className: "common", as: "textarea", rows: 6 })}
                </Form.Group>
              </Col>
              : null
            }
          </Row>
        </div>
      </Collapse>
    </Card >
  );
}

export default ProCard;