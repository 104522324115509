import React, { useEffect, useState } from "react";
import { Card, Collapse, Row, Col, Form } from "react-bootstrap";
// import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
// import * as Yup from 'yup';

const ExpCard = ({ exp, onDelete, onUpdate, showExperience, setShowExperience, handleSave }) => {

  const [experience, setExperience] = useState(exp);

  useEffect(() => {
    if (experience !== exp) {
      onUpdate(experience, experience.key);
    }
  }, [experience])

  const updateData = e => {
    e.preventDefault();
    setExperience({
      ...experience,
      [e.target.name]: e.target.value
    });
  }

  const setFieldValue = (val) => {
    setExperience({
      ...experience,
      to: val ? "" : "Present",
      isCurrent: !val,
    });
  }

  const Field = ({ name, className = "", placeholder = "", ...props }) => <Form.Control
    id={"exp" + name}
    name={name}
    className={className}
    value={experience[name]}
    onChange={updateData}
    placeholder={placeholder}
    {...props}
    onBlur={handleSave}
  />


  return (
    <Card className="mb-3 c" key={experience.key}>
      <div className="c-header" onClick={() => setShowExperience(showExperience === experience.key ? 0 : experience.key)}>
        <div className="c-title">
          {experience.designation || experience.organization ?
            <>{experience.designation} {experience.designation && experience.organization ? "at" : ""} {experience.organization}</>
            : "(Not specified)"
          }
        </div>
        <div style={{ rotate: showExperience === experience.key ? "-90deg" : "90deg", transitionDuration: "0.5s" }}>
          <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path d="M9.431 7.257l1.352-1.474 5.893 5.48a1 1 0 0 1 0 1.474l-5.893 5.45-1.352-1.475L14.521 12 9.43 7.257z"></path>
          </svg>
        </div>
      </div>
      <div className="c-delete" onClick={() => onDelete(experience.key)}>
        <i className="fa fa-trash" aria-hidden="true"></i>
      </div>
      <Collapse in={showExperience === experience.key}>
        <div className={`c-body`}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Job title</Form.Label>
                {Field({ name: "designation", className: "common" })}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Employer</Form.Label>
                {Field({ name: "organization", className: "common" })}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Start and End Date</Form.Label>
                <Row>
                  <Col xs={6} className="mb-3">
                    {Field({ name: "from", className: "common", placeholder: "MM / YYYY" })}
                  </Col>
                  <Col xs={6} className="mb-3">
                    {Field({ name: "to", className: "common", placeholder: "MM / YYYY" })}
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="custom-control custom-radio">
                <Form.Check
                  type="switch"
                  name="isPercentage"
                  checked={experience.isCurrent}
                  id="current"
                  onChange={() => setFieldValue(experience.isCurrent)}
                  label="Currently work here"
                  onBlur={handleSave}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>City</Form.Label>
                {Field({ name: "place", className: "common" })}
              </Form.Group>
            </Col>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label>Description</Form.Label>
                {Field({ name: "responsibility", className: "common", as: "textarea", rows: 6 })}
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card >
  );

}

export default React.memo(ExpCard);