import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { getUserId, getAdminId } from '../Auth';
import { MainLayout, HeaderLayout } from '../Layout/Layout';

// Pages
import Home from '../Home/Home';
import NoPage from '../Pages/NoPage';
import ListIndex from '../List/ListIndex';
import { getErrorNotification } from '../Utils/Notification';
import PreviewIndex from '../Preview/PreviewIndex';
import PreviousResumeIndex from '../MyResume/MyResumeIndex';
import AdminIndex from '../Admin/AdminIndex';
import Login from '../Admin/Login';
import AdminLayout from '../Layout/Admin/Layout';

const CommonComponent = ({
  component: Component,
  layout: Layout = MainLayout
}) => <Layout><Component /></Layout>

const AuthComponent = ({
  component: Component,
  layout: Layout = MainLayout
}) => {
  if (getUserId())
    return <Layout><Component /></Layout>
  else {
    getErrorNotification("Please Login to continue");
    return <Navigate to="/" />
  }
}

const AdminComponent = ({
  component: Component,
  layout: Layout
}) => {
  if (getAdminId())
    return <Layout><Component /></Layout>
  else {
    return <Navigate to="/admin/login" />
  }
}

const Base = () => {

  return <BrowserRouter>
    <Routes>

      <Route
        path="/"
        element={<CommonComponent component={Home} />}
      />

      <Route
        path="/list"
        element={<AuthComponent component={ListIndex} />}
      />

      <Route
        path="/preview"
        element={<AuthComponent component={PreviewIndex} layout={HeaderLayout} />}
      />

      <Route
        path="/my-resumes"
        element={<AuthComponent component={PreviousResumeIndex} />}
      />

      <Route
        path="/admin"
        element={<AdminComponent component={AdminIndex} layout={AdminLayout} />}
      />

      <Route
        path="/admin/login"
        element={<CommonComponent component={Login} layout={AdminLayout} />}
      />

      <Route
        path="*"
        element={<CommonComponent component={NoPage} />}
      />
    </Routes>
  </BrowserRouter>
}

export default Base;