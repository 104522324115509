import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../Utils/Breadcrumbs';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getTemplateList } from '../Utils/data';
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import { createResumeStart } from '../../store/actions/userAction';


const ListIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createResume } = useSelector(state => state.user);
  const [templates, setTemplates] = useState(getTemplateList());
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !createResume.loading && Object.keys(createResume.data).length > 0) {
      navigate(`/preview?id=${createResume.data.resume.id}`);
    }
    setSkipRender(false);
  }, [createResume]);

  const handleClick = (id) => dispatch(createResumeStart({ template_id: id }));

  return <>
    <div className="fables-header fables-after-overlay">
      <div className="container">
        <h2 className="fables-page-title fables-second-border-color">Template List</h2>
      </div>
    </div>
    <Container className="mt-5 mb-5">
      <Row>
        {templates.length > 0 ?
          templates.map((template, i) =>
            <Col sm={6} md={4} lg={4} key={template.id} className="mb-4">
              <Card className="cursor-pointer" onClick={e => handleClick(template.id)}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className="holder"
                >
                  <Card.Img variant="top" src={template.image} />
                  <div class="middle">
                    <div class="text">Use this template</div>
                  </div>
                </motion.div>
              </Card>
            </Col>
          )
          : <div>No Template Found</div>
        }
      </Row>
    </Container>
  </>
}

export default ListIndex;