import { getUserId } from '../../components/Auth';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BACKEND_URL;

const post = ({ base = baseUrl, url = "", data = {}, header = {} }) => {
  let finalUrl = base + url + "?";

  // const formData = new FormData();
  const uID = getUserId();
  // if (uID) finalUrl += "user_id=" + uID + "&";
  // finalUrl += "resume_id=" + data.resume_id;

  // Object.keys(data).map(key => formData.append(key, data[key]));

  let finalData = data;
  if (uID) finalData = { ...data, user_id: uID }

  return axios.post(finalUrl, JSON.stringify(finalData), header);
}

const mediaHeader = {
  headers: {
    'content-type': 'multipart/form-data',
  },
}

const postMedia = ({ base = baseUrl, url = "", data = {}, header = mediaHeader }) => {
  const finalUrl = base + url;
  const formData = new FormData();
  Object.keys(data).map(key => formData.append(key, data[key]));
  const uID = getUserId();
  if (uID) formData.append("user_id", uID);

  return axios.post(finalUrl, formData, header);
}

const get = ({ base = baseUrl, url = "", data = {}, header = {} }) => {

  let serilizeData = "?";
  const uID = getUserId();
  if (uID) serilizeData += "user_id=" + uID + "&";
  const keys = Object.keys(data);
  for (let i = 0; i < keys.length; i++) {
    serilizeData += keys[i] + "=" + data[keys[i]];
    if (i < keys.length - 1)
      serilizeData += "&";
  }

  const finalUrl = base + url + (serilizeData !== "?" ? serilizeData : "");
  return axios.get(finalUrl, {}, header);
}

const Api = { get, post, postMedia };

export default Api;