import React, { useEffect, useState } from "react";
import ReactPDF, { PDFViewer } from "@react-pdf/renderer";
import { Col } from "react-bootstrap";
import html from '../Templates/sampleTemplate';
import PaymentModal from "./PaymentModal";
// import Template1 from "../Templates/Template1/Template1";
import { useDispatch, useSelector } from "react-redux";
import { confirmPaymentStart, generateStripeStart } from "../../store/actions/userAction";
import { Document, Page, pdfjs } from "react-pdf";
import TemplateList from '../Templates/TemplateManager';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import useWindowSize from "../Utils/useWindowSize";

const LivePreview = ({ data, resume_id, showPreview, template_id }) => {
  const dispatch = useDispatch();
  const { generateStripe, confirmPayment } = useSelector(state => state.user);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [prevData, setPrevData] = useState();

  const [final, setFinal] = useState(true);

  pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js";

  const closePaymentModal = () => setShowPaymentModal(false);

  const handleDownloadClick = () => {
    dispatch(generateStripeStart({
      amount: 1200,
      currency: "inr",
      resume_id,
      // mode:"live"
    }));
  }

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(prevData)) {
      renderUrl()
        .then(generatedUrl => {
          if (generatedUrl) {
            setFinal(generatedUrl);
            setPrevData(data);
          }
        });
    }
  }, [data, template_id]);


  useEffect(() => {
    if (!skipRender && !generateStripe.loading && Object.keys(generateStripe.data).length > 0) {
      if (generateStripe.data.client_secret)
        setShowPaymentModal(generateStripe.data.client_secret);
      else
        handleDownload();
    }
  }, [generateStripe]);

  useEffect(() => {
    if (!skipRender && !confirmPayment.loading && Object.keys(confirmPayment.data).length > 0) {
      handleDownload();
      closePaymentModal();
    }
    setSkipRender(false);
  }, [confirmPayment]);

  const handlePaymentSuccess = (payment_id) => {
    dispatch(confirmPaymentStart({
      payment_id,
      resume_id,
    }));
  }


  const handleDownload = () => {
    let sampleTab = window.open()
    if (sampleTab) {
      renderUrl().then(generatedUrl => {
        if (generatedUrl) {
          let aTag = document.createElement('a')
          aTag.href = generatedUrl
          aTag.style = "display: none";
          aTag.download = 'Resume.pdf'
          document.body.appendChild(aTag)
          aTag.click()
        } // else -> means something went wrong during pdf generation
      }).catch(err => console.log(err))
    }
  }

  const renderUrl = async () => new Promise(async (resolve, reject) => {
    const Template = TemplateList[template_id];
    if (Template) {
      const blob = await ReactPDF.pdf(<Template data={data} />).toBlob()
      const url = URL.createObjectURL(blob)
      if (url && url.length > 0) {
        resolve(url)
      }
    }
  }
  ).then(res => res).catch(err => console.log(err))

  return (
    <>
      <Col md={12} lg={12} xl={6}
        style={{ background: "rgb(82, 86, 89)", }}
        className={`d-md-flex w-100 flex-column main-preview ${showPreview ? "show" : ""}`}
      >
        <div className="w-100 d-flex justify-content-end p-2">
          <button type="button" className="download-btn" onClick={() => handleDownloadClick()}>Download</button>
        </div>
        <div className="preview-sec">
          {/* <PDFtoIMG
            pdfComponent={
              <Template1 data={data} />
            }>
            {({ pages }) => {
              if (!pages.length) return 'Loading...';
              return pages.map((page, index) => <img key={index} src={page} />);
            }}
          </PDFtoIMG> */}
          {final ?
            <PDFPreview file={final} /> : null}
          {/* <PDFViewer style={{ width: "100%", height: "100%" }} showToolbar={false} id="pdfViewer">
            <Template1 data={data} />
          </PDFViewer> */}
        </div>
      </Col >
      {
        showPaymentModal ?
          <PaymentModal
            closeModal={closePaymentModal}
            data={data}
            secret={showPaymentModal}
            onSuccess={handlePaymentSuccess}
          />
          : null
      }
    </>
  );
}

const PDFPreview = ({ file }) => {
  const { size, updateElem } = useWindowSize();
  const [numPages, setNumPages] = useState();
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    updateElem(document.getElementById("preview"));
  }, []);

  return <div id="preview" className="w-100 prevContainer">
    <Document
      file={file}
      onLoadSuccess={({ numPages: nextNumPages }) => {
        setNumPages(nextNumPages);
      }}
    >
      {Array.from(new Array(numPages), (el, index) =>
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          className="mb-3 w-100"
          width={size[0] ? Math.min(size[0], 600) : 600}
          scale={zoom}
        />
      )}
    </Document>
      <div className="zoom-sec">
        <button onClick={() => setZoom(val => val + 0.1)} disabled={zoom >= 1.4}>+</button>
        <button onClick={() => setZoom(val => val - 0.1)} disabled={zoom === 1}>-</button>
      </div>
  </div>
};

export default LivePreview;