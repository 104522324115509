import { Link } from "react-router-dom";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";

const BuildResumeModal = ({ closeModal }) => {

  return <Modal
    show={true}
    onHide={closeModal}
    centered
    size="lg"
    backdrop="static"
    contentClassName="build-modal"
  >
    <Modal.Header closeButton>
      {/* <Modal.Title id="contained-modal-title-vcenter" className="col-11 text-center fables-second-text-color">
        Build Resume
      </Modal.Title> */}
    </Modal.Header>
    <Container>
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter" className="w-100 text-center fables-second-text-color heading">
          How do you want to start?
        </Modal.Title>
        <p className="text-center mb-5 heading-desc">Build a new resume or upload an existing file</p>
        <Row>
          <Col xs={12} className="mb-3">
            <Link to="/list" className="option-btn w-100">
              <img src="/images/create.png" />
              <div>
                Create New Resume
                <div className="desc">Choose a blank template and fill in the fields yourself</div>
              </div>
              <div style={{ position: "absolute", right: "30px" }}>
                <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path d="M9.431 7.257l1.352-1.474 5.893 5.48a1 1 0 0 1 0 1.474l-5.893 5.45-1.352-1.475L14.521 12 9.43 7.257z"></path>
                </svg>
              </div>
            </Link>
          </Col>
          <Col xs={12} className="mb-3">
            <Link to="/review" className="option-btn w-100">
              <img src="/images/upload.png" />
              <div>
                Upload Resume
                <div className="desc">Upload your existing resume in DOC, DOCX or PDF formats.</div>
              </div>
              <div style={{ position: "absolute", right: "30px" }}>
                <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path d="M9.431 7.257l1.352-1.474 5.893 5.48a1 1 0 0 1 0 1.474l-5.893 5.45-1.352-1.475L14.521 12 9.43 7.257z"></path>
                </svg>
              </div>
            </Link>
          </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
        <button type="submit" className="btn fables-second-background-color w-100" style={{ padding: "10px 30px", color: "#fff" }}>Add</button>
      </Modal.Footer> */}
    </Container>
  </Modal>
}

export default BuildResumeModal;