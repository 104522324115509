import { Link, useNavigate } from "react-router-dom";
import { getAdminId, removeAdminId } from "../../Auth";


const Header = () => {
  const navigate = useNavigate();

  const handleLogout = e => {
    e.preventDefault();
    removeAdminId();
    navigate("/admin/login");
  }

  return <div className="fables-navigation fables-main-background-color py-3 py-lg-0 cursor-pointer">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 col-lg-9 pr-md-0">
          <nav className="navbar navbar-expand-md btco-hover-menu py-lg-2">
            <Link className="navbar-brand pl-0" to="/">
              <img src="/images/logo.png" alt="Fables Template" className="fables-logo" />
            </Link>
          </nav>
        </div>
        {getAdminId() ?
          <div className="col-12 col-md-2 col-lg-3 pr-md-0 icons-header-mobile" onClick={handleLogout}>
            <div
              className="fables-third-text-color fables-second-hover-color font-13 top-header-link px-3 px-md-2 px-lg-4 max-line-height"
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              Logout
              {/* <div className="dropdown">
              <a href="#" className="fables-third-text-color dropdown-toggle right fables-second-hover-color max-line-height position-relative" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img
                  style={{ width: "40px", borderRadius: "50%" }}
                />
              </a>
            </div> */}

            </div>
          </div>
          : null
        }
      </div>
    </div>
  </div>

}

export default Header;