import { useEffect, useState } from "react";
import { Card, Container, Table } from "react-bootstrap";
import Loader from "../Utils/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../../store/actions/userAction";

const AdminIndex = () => {
  const dispatch = useDispatch();
  const { allOrders } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(getAllOrders());
  }, []);


  return <div className="admin">
    <Container>

      <h3 className="mt-5 mb-3">Welcome Admin !</h3>
      <Card className="pt-3 mb-5">
        <Card.Body>
          <Card.Title>Orders List</Card.Title>
          <Table  responsive striped>
            <thead className="table-head">
              <tr>
                <th >ID</th>
                <th >Name</th>
                <th >Image</th>
                <th >Email</th>
                <th >TemplateID</th>
                <th >Resume Name</th>
                <th >Amount</th>
                <th >Status</th>
              </tr>
            </thead>
            {!allOrders.loading && allOrders.data.orders && allOrders.data.orders.length > 0 ?
              <tbody>
                {allOrders.data.orders.map((order, i) =>
                  <tr>
                    <td>{order.id}</td>
                    <td>{order.user?.name}</td>
                    <td>{order.user?.picture ? <img src={order.user.picture} /> : "NILL"}</td>
                    <td>{order.user?.email}</td>
                    <td>{order.template_id}</td>
                    <td>{order.resume_name}</td>
                    <td>{order.amount}</td>
                    <td>
                      {order.paid == 1 ? `Paid On : ${order.payment_date}` : "No"}
                    </td>
                  </tr>
                )}
              </tbody>
              : null
            }
          </Table>
          {allOrders.loading ? <Loader />
            : !allOrders.data.orders ? <div className="text-danger w-100 text-center">Something went wrong</div>
              : allOrders.data.orders.length == 0 ? <div className="w-100 text-center">No Data found</div>
                : null
          }
        </Card.Body>
      </Card>
    </Container>
  </div>
}

export default AdminIndex;