import React, { Fragment } from 'react';
import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';



const Education = ({ data, padding }) => {

  const styles = StyleSheet.create({
    container: {
      paddingLeft: padding,
      paddingRight: padding,
      marginBottom: "20px",
      fontFamily: "Baskerville Old Face",
    },
    heading: {
      marginBottom: "8px",
      color: "#000",
    },
    subContainer: {
      borderLeft: "1px solid #00B050",
      paddingLeft: "20px",
    },
    topSection: {
      fontSize: "12px",
      color: "#00B050",
      lineHeight: "1.1px",
    },
    additional: {
      fontSize: "11px",
      marginBottom: "15px",
    },
  });

  if (data && data.length > 0)
    return (
      <View style={styles.container}>
        <Text style={styles.heading}>EDUCATION</Text>
        <View style={styles.subContainer}>
          {data.map((d, i) => <Fragment key={i}>
            <Text style={styles.topSection}>
              {d.course}
            </Text>
            <Text style={styles.additional}>
              {d.university}
              {d.university && d.institute ? ", " : ""}
              {d.institute}
              {d.passingYear ? ` (${d.passingYear})` : ""}
            </Text>
          </Fragment>
          )}
        </View>
      </View>
    );
  else return null;
}

export default Education;