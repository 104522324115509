import { useState } from "react";
import { Row } from "react-bootstrap";
import EduCard from "./EduCard";


const EducationSec = ({ educations, setEducations, handleSave }) => {
  const [showEducation, setShowEducation] = useState(0);

  const addExp = () => {
    const oldKey = educations.length > 0 ? Math.max(...educations.map(o => o.key)) : 0;
    setEducations(old => [...old, {
      key: oldKey + 1,
      institute: "",
      university: "",
      passingYear: "",
      course: "",
      percentage: "",
      isPercentage: true,
      isGraduated: true,
    }]);
    setShowEducation(oldKey + 1);
  }

  const onDelete = (key) => {
    setEducations(data => data.filter(edu => edu.key != key));
  }

  const onUpdate = (newData, key) => {
    setEducations(data => data.map(oldData => oldData.key === key ? newData : oldData));
  }

  return (
    <Row className="mb-5">
      <div className="sub-title">Education</div>
      <p className="sub-title-desc mb-3">A varied education on your resume sums up the value that your learnings and background will bring to job.</p>

      {educations.map((edu, i) =>
        <EduCard
          key={edu.key}
          edu={edu}
          onDelete={onDelete}
          onUpdate={onUpdate}
          showEducation={showEducation}
          setShowEducation={setShowEducation}
          handleSave={handleSave}
        />)}

      <div className="d-flex justify-content-start fables-second-text-color">
        <span onClick={() => addExp()}>
          <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <polygon points="13 11 17 11 17 13 13 13 13 17 11 17 11 13 7 13 7 11 11 11 11 7 13 7"></polygon>
          </svg>
          {educations.length > 0 ? "Add one more education" : "Add education"}
        </span>
      </div>
    </Row>
  );
}

export default EducationSec;