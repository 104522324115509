import Template1 from "./Template1/Template1";
import Template2 from "./Template2/Template2";

const list = {
  1: Template1,
  2: Template2,
  3: Template1,
  4: Template1,
  5: Template1
};

export default list;