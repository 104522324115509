import React from 'react';
import VideoSection from './VideoSection';
import TemplateSection from './TemplateSection';
import Partners from './Partners';
import Features from './Features';
import Reviews from './Reviews';
import Teams from './Teams';
import Contact from './Contact';
import ContentSec from './ContentSec';


const Home = () => {

  return (
    <>
      <a href="https://api.whatsapp.com/send?phone=+971557963534&text=Hi" target="_blank">
        <img src="/images/whatsapp.png" className="floating-btn" />
      </a>
      <VideoSection />
      {/* <TemplateSection /> */}
      <ContentSec />
      <Partners />
      <Features />
      <Reviews />
      <Teams />
      <Contact />
    </>
  );
}

export default Home;