

const Teams = () => {


  return (
    <>
      <div className="container">
        <div className="row my-4 my-md-5">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
            <h2 className="font-35 font-weight-bold text-center fables-main-text-color">Team</h2>
            <p className="fables-forth-text-color">
              Pillers of Career Catalyst. who manages the team and handling to achieve Sucess.
            </p>
          </div>
        </div>
        <div className="fables-team">
          <div className="row overflow-hidden">
          <div className="col-6 col-md-3 wow bounceInDown mb-3" data-wow-duration="2s" data-wow-delay="1.6s">
              <div className="card fables-team-block fables-second-hover-text-color">
                <div className="image-container shine-effect">
                  <a href="#">
                    <img className="w-100" src="/images/team/ashiqu.jpg" alt="Fables Template" />
                  </a>
                </div>
                <div className="card-body">
                  <h5><a href="#" className="font-19 semi-font fables-main-text-color team-name">ASHIQU HUSSAIN</a></h5>
                  <p className="font-13 fables-fifth-text-color italic my-2">CEO United Arab Emirates</p>
                  <ul className="nav fables-team-social-links">
                    <li><a href="#" target="_blank"><span className="fables-iconlinkedin-icon fables-forth-text-color fables-fifth-border-color fables-team-social-icon"></span></a></li>
                    <li><a href="#" target="_blank"><span className="fables-icongooglePlus fables-forth-text-color fables-fifth-border-color fables-team-social-icon"></span></a></li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3 wow bounceInDown mb-3" data-wow-duration="2s" data-wow-delay=".4s">
              <div className="card fables-team-block fables-second-hover-text-color">
                <div className="image-container shine-effect">
                  <a href="#">
                    <img className="w-100" src="/images/team/ansif1.jpeg" alt="Fables Template" />
                  </a>
                </div>
                <div className="card-body">
                  <h5><a href="#" className="font-19 semi-font fables-main-text-color team-name">ANSIF ALI</a></h5>
                  <p className="font-13 fables-fifth-text-color italic my-2">Operation Head in UK</p>
                  <ul className="nav fables-team-social-links">
                    <li><a href="#" target="_blank"><span className="fables-iconlinkedin-icon fables-forth-text-color fables-fifth-border-color fables-team-social-icon"></span></a></li>
                    <li><a href="#" target="_blank"><span className="fables-icongooglePlus fables-forth-text-color fables-fifth-border-color fables-team-social-icon"></span></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 wow bounceInDown mb-3" data-wow-duration="2s" data-wow-delay="1.2s">
              <div className="card fables-team-block fables-second-hover-text-color">
                <div className="image-container shine-effect">
                  <a href="#">
                    <img className="w-100" src="/images/team/meryem1.jpeg" alt="Fables Template" />
                  </a>
                </div>
                <div className="card-body">
                  <h5><a href="#" className="font-19 semi-font fables-main-text-color team-name">MERYEM IKHLAS</a></h5>
                  <p className="font-13 fables-fifth-text-color italic my-2">Operations Head in Morocco</p>
                  <ul className="nav fables-team-social-links">
                    <li><a href="#" target="_blank"><span className="fables-iconlinkedin-icon fables-forth-text-color fables-fifth-border-color fables-team-social-icon"></span></a></li>
                    <li><a href="#" target="_blank"><span className="fables-icongooglePlus fables-forth-text-color fables-fifth-border-color fables-team-social-icon"></span></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 wow bounceInDown mb-3" data-wow-duration="2s" data-wow-delay=".8s">
              <div className="card fables-team-block fables-second-hover-text-color">
                <div className="image-container shine-effect">
                  <a href="#">
                    <img className="w-100" src="/images/team/junaid_1.jpeg" alt="Fables Template" />
                  </a>
                </div>
                <div className="card-body">
                  <h5><a href="#" className="font-19 semi-font fables-main-text-color team-name">JUNAID</a></h5>
                  <p className="font-13 fables-fifth-text-color italic my-2">Operation Head in India</p>
                  <ul className="nav fables-team-social-links">
                    <li><a href="#" target="_blank"><span className="fables-iconlinkedin-icon fables-forth-text-color fables-fifth-border-color fables-team-social-icon"></span></a></li>
                    <li><a href="#" target="_blank"><span className="fables-icongooglePlus fables-forth-text-color fables-fifth-border-color fables-team-social-icon"></span></a></li>
                  </ul>
                </div>
              </div>
            </div>
            
            
          </div>

        </div>
      </div>
    </>
  );
}

export default Teams;