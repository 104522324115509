import React, { useEffect, useLayoutEffect, useState } from 'react';

export default function useWindowSize(elem = window) {
  const [size, setSize] = useState([0, 0]);
  function updateSize() {
    setSize([elem?.clientWidth || 0, elem?.clientHeight || 0]);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const updateElem = newElem => {
    elem = newElem;
    updateSize();
  }

  return { size, updateElem };
}