import React, { useEffect, useState } from 'react';
import { PaymentElement, useElements, useStripe, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

const CheckoutForm = ({ onSuccess, secret }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { confirmPayment } = useSelector(state => state.user);
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState();
  const [name, setName] = useState("");

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '18px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
    showIcon: true,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);
    if (!card) return;
    setIsProcessing(true);
    try {

      const payload = await stripe.confirmCardPayment(
        secret,
        {
          payment_method: {
            card: card,
            billing_details: {
              name,
            },
          },
        },
      );

      if (payload.error) {
        setMessage(payload.error.message);
        setIsProcessing(false);
        return;
      }

      // console.log(payload.paymentIntent.id);
      onSuccess(payload.paymentIntent.id);
    } catch (e) {
      console.log(e);
      setIsProcessing(false);
    }

    setIsProcessing(false);
  }

  return (<form onSubmit={handleSubmit}>
    {/* <PaymentElement /> */}
    <div className="stripeSec">
      <label className="w-100">
        <span>Card number</span>
        <CardNumberElement options={ELEMENT_OPTIONS} />
      </label>
      <Row>
        <Col xs={6}>
          <label className="w-100">
            <span>Expiration</span>
            <CardExpiryElement options={ELEMENT_OPTIONS} />
          </label>
        </Col>
        <Col xs={6}>
          <label className="w-100">
            <span>CVC</span>
            <CardCvcElement options={ELEMENT_OPTIONS} />
          </label>
        </Col>
      </Row>
      <label className="w-100">
        <span>Card holder name</span>
        <input type="text" value={name} onChange={e => setName(e.target.value)} className="form-control" />
      </label>
    </div>
    <button type="submit" className="payment-btn" disabled={isProcessing}>
      {isProcessing || confirmPayment.buttonDisable ? "Processing ..." : "Pay now"}
    </button>
    {message && <div>{message}</div>}
  </form>);
}

export default CheckoutForm;