import { Card, Collapse, Row, Col, Form } from "react-bootstrap";

const EduCard = ({ edu, onDelete, onUpdate, showEducation, setShowEducation, handleSave }) => {

  const updateData = e => {
    e.preventDefault();
    onUpdate({
      ...edu,
      [e.target.name]: e.target.value
    }, edu.key)
  }

  const Field = ({ name, className = "", placeholder = "", ...props }) => <Form.Control
    id={"edu" + name}
    name={name}
    className={className}
    value={edu[name]}
    onChange={updateData}
    placeholder={placeholder}
    {...props}
    onBlur={handleSave}
  />


  // institute: "UIT",
  //   university: "Kerala University",
  //   passingYear: "2017",
  //   course: "BSc Computer Science",
  //   percentage: "67",
  //   isPercentage: true

  return (
    <Card className="mb-3 c">
      <div className="c-header" onClick={() => setShowEducation(showEducation === edu.key ? 0 : edu.key)}>
        <div className="c-title">
          {edu.course || edu.institute ?
            <>{edu.course} {edu.course && edu.institute ? "at" : ""} {edu.institute}</>
            : "(Not specified)"
          }
        </div>
        <div style={{ rotate: showEducation === edu.key ? "-90deg" : "90deg", transitionDuration: "0.5s" }}>
          <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path d="M9.431 7.257l1.352-1.474 5.893 5.48a1 1 0 0 1 0 1.474l-5.893 5.45-1.352-1.475L14.521 12 9.43 7.257z"></path>
          </svg>
        </div>
      </div>
      <div className="c-delete" onClick={() => onDelete(edu.key)}>
        <i className="fa fa-trash" aria-hidden="true"></i>
      </div>
      <Collapse in={showEducation === edu.key}>
        <div className={`c-body`}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Institute</Form.Label>
                {Field({ name: "institute", className: "common" })}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Course</Form.Label>
                {Field({ name: "course", className: "common" })}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Mark</Form.Label>
                {Field({ name: "percentage", className: "common" })}
              </Form.Group>
              <Form.Group className="custom-control custom-radio">
                <Form.Check
                  type="switch"
                  name="isPercentage"
                  checked={edu.isPercentage}
                  id="percentage"
                  onChange={() => {
                    onUpdate({
                      ...edu,
                      isPercentage: !edu.isPercentage,
                    }, edu.key)
                  }}
                  label="In percentage"
                  onBlur={handleSave}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>passing Year</Form.Label>
                {Field({ name: "passingYear", className: "common", disabled: !edu.isGraduated })}
              </Form.Group>
              <Form.Group className="custom-control custom-radio">
                <Form.Check
                  type="switch"
                  name="isGraduated"
                  checked={edu.isGraduated}
                  id="graduated"
                  onChange={() => {
                    onUpdate({
                      ...edu,
                      isGraduated: !edu.isGraduated,
                      passingYear: "",
                    }, edu.key)
                  }}
                  label="Graduated"
                  onBlur={handleSave}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Universiry / Board</Form.Label>
                {Field({ name: "university", className: "common" })}
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card >
  );
}

export default EduCard;