import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Copyright from './Copyright';


export const MainLayout = ({ children }) => <>
  <Header />
  {children}
  <Footer />
  <Copyright />
</>

export const HeaderLayout = ({ children }) => <>
  <Header />
  {children}
</>