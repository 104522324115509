import React from 'react';

import { Link, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';


const Header = ({ data, padding }) => {

  const styles = StyleSheet.create({
    container: {
      borderBottom: "1px solid #000",
      display: "flex",
      flexDirection: 'column',
      alignItems: "center",
      paddingBottom: "10px",
      marginBottom: "10px",
      paddingLeft: padding,
      paddingRight: padding,
    },
    name: {
      fontSize: "20px",
      marginBottom: "8px",
      color: "#404040",
      fontFamily: "Baskerville Old Face",
    },
    designation: {
      fontSize: "14px",
      marginBottom: "8px",
      color: "#404040",
      fontFamily: "Corbel"
    },
    linkText: {
      fontSize: "12px",
      color: "#404040",
      fontFamily: "Courier New",
    },
    description: {
      fontSize: "14px",
      whiteSpace: "break-spaces",
      paddingLeft: padding,
      paddingRight: padding,
      lineHeight: "1.5px",
      marginBottom: "8px",
      color: "#404040",
      fontFamily: "Baskerville Old Face",
    }
  });


  return (
    <>
      {data.name || data.designation || data.mobile || data.email ?
        <View style={styles.container}>
          <Text style={styles.name}>{data.name}</Text>
          <Text style={styles.designation}>{data.designation}</Text>
          <Text style={styles.linkText}>{data.mobile}</Text>
          <Text style={styles.linkText}>{data.email}</Text>
        </View>
        : null
      }
      <Text style={styles.description}>{data.description}</Text>
    </>
  )
}

export default Header;