import React from 'react';

import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: 'row',
    // borderBottomWidth: 2,
    // borderBottomColor: '#112131',
    // borderBottomStyle: 'solid',
    alignItems: 'stretch',
    justifyContent: "space-between"
  },
  imageColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    flexGrow: 9,
  },
  detailColumn: {
    display: "flex",
    flexDirection: 'column',
    textTransform: 'uppercase',
    alignItems: "center",
    justifyContent: "center",
  },
  linkColumn: {
    display: "flex",
    flexDirection: 'column',
    flexGrow: 2,
    fontSize: 9.96,
    // alignSelf: 'flex-end',
    // justifySelf: 'flex-end',
  },
  image: {
    width: 90,
    height: 100,
    border: "2px solid #002060",
    marginRight: 20,
  },
  name: {
    fontSize: 21.96,
    // fontFamily: 'Lato Bold',
    color: "#002060",
  },
  designation: {
    fontSize: 14.04,
    justifySelf: 'flex-end',
    // fontFamily: 'Lato',
  },
  descriptionSec: {
    paddingTop: 18,
    display: "flex",
  },
  description: {
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  singleLink: {
    display: "flex",
    justifyContent: "end",
  },
  linkText: {
    textAlign: "end",
  }
});

export default ({ data }) => (
  <>
    <View style={styles.container}>
      <View style={styles.imageColumn}>
        {data.profile ?
          <Image
            src={data.profile}
            style={styles.image}
          />
          : null
        }
        <View style={styles.detailColumn}>
          <Text style={styles.name}>{data.name}</Text>
          <Text style={styles.designation}>{data.designation}</Text>
        </View>
      </View>
      <View style={styles.linkColumn}>
        <View style={styles.singleLink}>
          <Text style={styles.linkText}>{data.mobile}</Text>
        </View>
        <View style={styles.singleLink}>
          <Text style={styles.linkText}>{data.email}</Text>
        </View>
      </View >
    </View >
    <View style={styles.descriptionSec}>
      <Text style={styles.description}>{data.description}</Text>
    </View>
  </>
);