import GoogleMapReact from 'google-map-react';


const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Contact = () => {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };

  return (
    <>
      <div className="fables-testimonial fables-after-overlay fables-about-caption py-5 bg-rules mb-5" style={{ backgroundImage: "url(/custom/images/index2-overlay.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="position-relative z-index col-12 col-md-8 offset-md-2 text-center wow zoomIn" data-wow-duration="2s">
              <h3 className="fables-second-text-color mb-3 font-25 font-weight-bold">Contact Us</h3>
              <p className="font-weight-light fables-third-text-color">
                Reach us on our social media Platforms. and Physically.
              </p>
              {/* <a href="contactus1.html" className="btn fables-second-background-color white-color white-color-hover fables-btn-rounded  mt-4 py-2 px-5">Contact us</a> */}
            </div>
          </div>

        </div>
      </div>
      <div className="container">
        <div className="row mb-4 mb-md-5 overflow-hidden">
          <div className="col-12 col-sm-6 wow fadeInLeft">
            <form className="fables-contact-form">
              <div className="form-group">
                <input type="text" className="form-control rounded-0 p-3" placeholder="Name" />
              </div>
              <div className="form-group">
                <input type="email" className="form-control rounded-0 p-3" placeholder="Email" />
              </div>
              <div className="form-group">
                <input type="text" className="form-control rounded-0 p-3" placeholder="Subject" />
              </div>
              <div className="form-group">
                <textarea className="form-control rounded-0 p-3" placeholder="Message" rows="3"></textarea>
              </div>
              <button type="submit" className="btn fables-second-background-color rounded-0 text-white btn-block p-3">Send</button>
            </form>
          </div>
          <div className="col-12 col-sm-6 wow fadeInRight fables-third-background-color">
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              <AnyReactComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
              />
            </GoogleMapReact>
            {/* <div id="map" data-lng="31.248848" data-lat="29.966324" data-icon="assets/custom/images/map-marker.png" data-zom="12" style={{ width: "100%", height: "420px" }}></div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;