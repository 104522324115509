import React, { Fragment } from 'react';

import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  heading: {
    display: "flex",
    marginTop: 20,
    width: "100%",
    fontWeight: "bold",
    fontSize: 18,
  },
  divider: {
    display: "flex",
    height: 1,
    justifyContent: "center",
  },
  line: {
    width: "calc( 100% - 15px )",
    borderBottom: "1px solid #000",
  },
  topSec: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 5,
    marginBottom: 5,
  },
  jobTitle: {
    fontSize: 15.96,
    color: "#002060",
    fontWeight: "bold",
  },
  fromTo: {
    color: "#002060",
    fontSize: 12,
    textTransform: "uppercase",
  },
  companySec: {
    display: "flex",
    marginTop: -10,
  },
  company: {
    fontSize: 12,
    fontWeight: "bold",
  },
  description: {
    display: "flex",
    fontSize: 12,
    whiteSpace: "break-spaces",
  }
});


export default ({ data }) => {

  if (data && data.length > 0)
    return (
      <View>
        <Text style={styles.heading}>Project</Text>
        <View style={styles.divider}>
          <View style={styles.line}></View>
        </View>

        {data.map((d, i) => <Fragment key={i}>
          <View style={styles.topSec}>
            <Text style={styles.jobTitle}>{d.title}</Text>
          </View>
          <View style={styles.description}>
            <Text >{d.description}</Text>
          </View>
        </Fragment>)}

      </View>
    )
  else return null;
}