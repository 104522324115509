import React, { Fragment } from 'react';

import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';



const Experiecnce = ({ data, padding }) => {

  const styles = StyleSheet.create({
    container: {
      paddingLeft: padding,
      paddingRight: padding,
      marginBottom: "20px",
      fontFamily: "Baskerville Old Face",
    },
    heading: {
      marginBottom: "8px",
      color: "#000",
    },
    subContainer: {
      borderLeft: "1px solid #00B050",
      paddingLeft: "20px",
    },
    topSection: {
      fontSize: "12px",
      color: "#00B050",
      lineHeight: "1.1px",
    },
    from: {
      fontSize: "11px",
      marginBottom: "15px",
    },
    responsibility: {
      fontSize: "12px",
      lineHeight: "1.5px",
      marginBottom: "20px",
    }
  });

  if (data && data.length > 0)
    return (
      <View style={styles.container}>
        <Text style={styles.heading}>EXPERIENCE</Text>
        <View style={styles.subContainer}>
          {data.map((d, i) => <Fragment key={i}>
            <Text style={styles.topSection}>
              {d.designation}
              {d.designation && d.organization ? " - " : ""}
              {d.organization}
              {d.organization && d.place ? ", " : ""}
              {d.place}
            </Text>
            {d.from || d.to ?
              <Text style={styles.from}>
                {d.from}
                {d.from && d.to ? " TO " : ""}
                {d.to}
              </Text> : null}
            {d.responsibility ?
              <Text style={styles.responsibility}>{d.responsibility}</Text>
              : null
            }
          </Fragment>
          )}
        </View>
      </View>
    );
  else return null;

}

export default Experiecnce;