// import dynamic from 'next/dynamic';
import { Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';

// var $ = require("jquery");
// if (typeof window !== "undefined") {
//   window.$ = window.jQuery = require("jquery");
// }

// const OwlCarousel = dynamic(() => import("react-owl-carousel"), {
//   ssr: false,
// });

const Partners = () => {

  const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplaySpeed: 3000,
    items: 3,
  }

  const partners = [
    { id: 1, img: "/images/Du_telecom_logo.jpg", alt: "partner" },
    { id: 2, img: "/images/al_anzari.png", alt: "partner" },
    { id: 3, img: "/images/ADCB_Logo.jpg", alt: "partner" },
    { id: 4, img: "/images/emirates.jpeg", alt: "partner" },
    { id: 5, img: "/images/adnoc.jpeg", alt: "partner" },
    { id: 6, img: "/images/dpworli.jpeg", alt: "partner" },
    { id: 7, img: "/images/etisalat.jpeg", alt: "partner" },
    { id: 8, img: "/images/majid_al_futtaim.jpeg", alt: "partner" },
    { id: 8, img: "/images/aramco.jpeg", alt: "partner" },
    { id: 8, img: "/images/landmark_group.jpeg", alt: "partner" },
    { id: 8, img: "/images/etihai_airways.jpeg", alt: "partner" },
    { id: 8, img: "/images/emmar.jpeg", alt: "partner" },
    { id: 8, img: "/images/emirates_nbd.jpeg", alt: "partner" },
  ]

  return (
    <>
      <Container>
        <div className="row my-4 my-md-5">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
            <h2 className="font-35 font-weight-bold fables-second-text-color mb-4">Hired By</h2>
            <p className="fables-forth-text-color">
              Top Companies, which our clients had placed
            </p>
          </div>
        </div>
      </Container>
      <div className="fables-light-background-color py-4">
        <div className="container">
          <OwlCarousel className='owl-theme' {...options}>
            {partners.map((pat) =>
              <div id={pat.id}>
                <img src={pat.img} alt={pat.alt} className="fables-partner-carousel-img" />
              </div>
            )}

          </OwlCarousel>
        </div>
      </div>
    </>
  );
}

export default Partners;