import { createSlice } from '@reduxjs/toolkit';
import {
  confirmPaymentStart,
  createResumeStart,
  generateStripeStart,
  getResumeDataStart,
  getUserStart,
  loginStart,
  resumeListStart,
  updateProfilePicStart,
  updateResumeStart,
  getAllOrders
} from "../actions/userAction";
import { getFailureData, getFulfilledData, getInitialData, getPendingData } from '../sliceUtils';
import { removeUserId, setUserId } from '../../components/Auth';

const initialState = {
  login: getInitialData(),
  userDetails: getInitialData(),
  createResume: getInitialData(),
  getResumeData: getInitialData(),
  updateResume: getInitialData(),
  resumeList: getInitialData(),
  generateStripe: getInitialData(),
  confirmPayment: getInitialData(),
  updateProfilePic: getInitialData(),

  tempData: getInitialData(),

  // Admin
  allOrders: getInitialData(),
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.userDetails = getInitialData();
      removeUserId();
    },
    setUserDetails: (state, { payload }) => { state.tempData = payload }
  },
  extraReducers: (builder) => {
    // USER LOGIN
    builder
      .addCase(loginStart.pending, (state) => { state.login = getPendingData() })
      .addCase(loginStart.fulfilled, (state, { payload }) => {
        console.log(payload);
        if (payload.status) setUserId(payload.data.user.id);
        state.login = getFulfilledData({ payload });
        state.userDetails = getFulfilledData({ payload, successNotify: false, errorNotify: false });
      })
      .addCase(loginStart.rejected, (state, { error }) => { state.login = getFailureData({ error }) });

    // FETCH USER DETAILS
    builder
      .addCase(getUserStart.pending, (state) => { state.userDetails = getPendingData() })
      .addCase(getUserStart.fulfilled, (state, { payload }) => { state.userDetails = getFulfilledData({ payload, successNotify: false }) })
      .addCase(getUserStart.rejected, (state, { error }) => { state.userDetails = getFailureData({ error }) });

    // CREATE NEW RESUME
    builder
      .addCase(createResumeStart.pending, (state) => { state.createResume = getPendingData() })
      .addCase(createResumeStart.fulfilled, (state, { payload }) => { state.createResume = getFulfilledData({ payload, successNotify: false }) })
      .addCase(createResumeStart.rejected, (state, { error }) => { state.createResume = getFailureData({ error }) });

    // GET RESUME DATA
    builder
      .addCase(getResumeDataStart.pending, (state) => { state.getResumeData = getPendingData() })
      .addCase(getResumeDataStart.fulfilled, (state, { payload }) => { state.getResumeData = getFulfilledData({ payload, successNotify: false }) })
      .addCase(getResumeDataStart.rejected, (state, { error }) => { state.getResumeData = getFailureData({ error }) });

    // UPDATE RESUME DATA
    builder
      .addCase(updateResumeStart.pending, (state) => { state.updateResume = getPendingData() })
      .addCase(updateResumeStart.fulfilled, (state, { payload }) => { state.updateResume = getFulfilledData({ payload, successNotify: false }) })
      .addCase(updateResumeStart.rejected, (state, { error }) => { state.updateResume = getFailureData({ error }) });

    // USER RESUME LIST
    builder
      .addCase(resumeListStart.pending, (state) => { state.resumeList = getPendingData() })
      .addCase(resumeListStart.fulfilled, (state, { payload }) => { state.resumeList = getFulfilledData({ payload, successNotify: false }) })
      .addCase(resumeListStart.rejected, (state, { error }) => { state.resumeList = getFailureData({ error }) });

    //GENERATE STRIPE TOKEN
    builder
      .addCase(generateStripeStart.pending, (state) => { state.generateStripe = getPendingData() })
      .addCase(generateStripeStart.fulfilled, (state, { payload }) => { state.generateStripe = getFulfilledData({ payload, successNotify: false }) })
      .addCase(generateStripeStart.rejected, (state, { error }) => { state.generateStripe = getFailureData({ error }) });

    // CONFIRM PAYMENT
    builder
      .addCase(confirmPaymentStart.pending, (state) => { state.confirmPayment = getPendingData() })
      .addCase(confirmPaymentStart.fulfilled, (state, { payload }) => { state.confirmPayment = getFulfilledData({ payload, successNotify: false }) })
      .addCase(confirmPaymentStart.rejected, (state, { error }) => { state.confirmPayment = getFailureData({ error }) });

    //UPDATE PROFILE PIC
    builder
      .addCase(updateProfilePicStart.pending, (state) => { state.updateProfilePic = getPendingData() })
      .addCase(updateProfilePicStart.fulfilled, (state, { payload }) => { state.updateProfilePic = getFulfilledData({ payload, successNotify: false }) })
      .addCase(updateProfilePicStart.rejected, (state, { error }) => { state.updateProfilePic = getFailureData({ error }) });

    //Admin All Order List
    builder
      .addCase(getAllOrders.pending, (state) => { state.allOrders = getPendingData() })
      .addCase(getAllOrders.fulfilled, (state, { payload }) => { state.allOrders = getFulfilledData({ payload, successNotify: false }) })
      .addCase(getAllOrders.rejected, (state, { error }) => { state.allOrders = getFailureData({ error }) });
  }
});


export default userSlice.reducer;

export const {
  logout,
  setUserDetails
} = userSlice.actions;